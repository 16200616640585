<template>
  <Box>

    <Row>
      <Column>
        <UserAdd />
      </Column>
    </Row>
    
  </Box>
</template>
<script>
import { mapGetters } from 'vuex'

import ConstUtils   from "@/utils/ConstUtils.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import UserAdd from '@/views/portal/employees/add/AddEmployee.vue';

export default {
  name: "portal-customer-operator-employee-file",
  components: {
    Box,Row,Column, 
    UserAdd
  },
  data() {
    return {
      id: this.$route.params["id"],
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'domain',
                ]),
    userData: function() {
      return this.user.data;
    },
    user: function() {
      return this.employee.user().find();
    },
    
    employeeData: function() {
      return this.employee.data;
    },
    employee: function() {
      return this.domain.employees().findById(this.id);
    },
  },
  watch: {
    
  },
  mounted: function() {
    if (!this.auth_connected) {
      this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.SIGNIN, });
    }
  },
  methods: {
    
  },
}
</script>